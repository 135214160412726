import React, { Fragment, useContext } from "react";
import Jumbotron from "react-bootstrap/";
//import Image from "react-bootstrap/Image";
//import Button from "react-bootstrap/Button";
//import MainImage1 from "../Assets/Img/MainImage01.jpg";
import "../Assets/Css/body.css";
import "../Assets/Css/heading.css";
import "../Assets/Css/styles.css";
import AppContext from "./AppContext";
import { WelcomeText, BannerText } from "./Texts.js";
import Icofont from "react-icofont";

const divStyle = {
  backgroundImage:
    "linear-gradient(to bottom, rgba(255,0,0,0), rgba(0,0,0,0.0), rgba(0, 0, 0, 0.150)), url(/img/Hochzeit_Page_Header.jpeg)",
  //"linear-gradient(to bottom, rgba(255,0,0,0), rgba(0,0,0,0.0), rgba(0, 0, 0, 0.150)), url(" + MainImage1 + ")",
  height: "500px",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  position: "relative",
};

const heroText = {
  textAlign: "center",
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const Header = (props) => {
  const context = useContext(AppContext);

  return (
    <Fragment>
      <header className="bg-primary text-white text-center" id="home">
        {/* <header class="masthead bg-primary text-white text-center"> */}
        <div
          className="jumbotron"
          style={{
            backgroundImage:
              "linear-gradient(to bottom, rgba(255,0,0,0), rgba(0,0,0,0.0), rgba(0, 0, 0, 0.150)), url(" + props.image_page_header + ")",
            height: "500px",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            position: "relative",
            marginBottom: "0px"
          }}
          fluid="true"
        >
          <div style={heroText}>
            <h1 className="masthead">{context.lang.get === "en" ? BannerText[0].title_DE : BannerText[0].title_ENG}</h1>
            <h1 className="mb-3">
              {context.lang.get === "en" ? BannerText[0].subtitle_DE : BannerText[0].subtitle_ENG}
            </h1>
            {context.lang.get === "en"
              ? BannerText[0].text_DE.split("\n").map((item, i) => (
                  <h5 className="mb-3" key={i}>
                    {item}
                  </h5>
                ))
              : BannerText[0].text_ENG.split("\n").map((item, i) => (
                  <h5 className="mb-3" key={i}>
                    {item}
                  </h5>
                ))}
            {/* <p>
							<Button variant="primary">Learn more</Button>
						</p> */}
          </div>
        </div>
      </header>
    </Fragment>
  );
};
export default Header;
