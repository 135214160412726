import React, { Fragment, useContext } from "react";
import { useParams } from "react-router-dom";

import { Helmet } from "react-helmet";
//import CardDeck from "react-bootstrap/CardDeck";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Icofont from "react-icofont";

import Header from "../Components/Header";

import Navigation from "../Components/Navigation";
import Copyrights from "../Components/Copyrights";
import AppContext from "../Components/AppContext";
import OrderForm from "../Components/OrderForm";
import Footer from "./Footer";

import { GiftCategories, Gifts } from "../Components/Texts";

import "../Assets/Css/body.css";
import "../Assets/Css/heading.css";
import "../Assets/Css/styles.css";
import curvy_line from "../Assets/Img/curvy_line.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { ProStrings, WelcomTextProStrings } from "./Texts.js";

const styleMainImage = {
  width: "auto",
  height: "auto",
  display: "block",
  marginLeft: "0px",
  marginRight: "auto",
  marginBottom: "24px",
};

const styleImage = {
  width: "auto",
  height: "auto",
  marginBottom: "12px",
};

const styleRow = {
  display: "flex",
  textAlign: "center",
  marginBottom: "16px",
};

const styleColumnCenter = {
  textAlign: "center",
};

const styleColumnLeft = {
  display: "block",
  marginRight: "auto",
  marginLeft: "0px",
};

const styleColumnRight = {
  textAlign: "right",
  marginLeft: "auto",
  marginRight: "0px",
};

const styleRoundBorder = {
  borderRadius: "10px",
  border: "0px solid #7b9cc0",
  padding: "18px",
  marginBottom: "30px",
  backgroundColor: "#fbf8f3",
};

export const DisplayGeschenke = (props) => {
  const context = useContext(AppContext);
  const { categoryId } = useParams();
  const giftCatergory = GiftCategories.find(({ id }) => id === categoryId);
  const giftsFiltered = Gifts.filter((gift) => gift.category == giftCatergory.id);
  //console.log(giftsFiltered);
  return (
    <Fragment>
      <Helmet>
        <html lang={props.language && props.language === "en" ? "en" : "de"} />
      </Helmet>
      <Navigation />
      <Header image_page_header={giftCatergory.image_page_header} />
      <section className="page-section portfolio" id={giftCatergory.title_DE}>
        <div className="container text-center">
          <div className="text-center">
            <h2 className="page-section-heading text-secondary mb-3 d-inline-block">
              {giftCatergory.title_DE}
              {/* {context.lang.get === "en" ? props.WelcomeText[0].title_DE : props.WelcomeText[0].title_ENG} */}
            </h2>
          </div>

          <div class="divider-custom  mb-0">
            <div class="divider-custom-line"></div>
            <div class="divider-custom-icon">
              <FontAwesomeIcon icon="fa-solid fa-candy-cane" />
            </div>
            <div class="divider-custom-line"></div>
          </div>

          {/* <img src={curvy_line} className="mb-3" /> */}

          {/* <p className="text-secondary lead">
            {context.lang.get === "en" ? props.WelcomeText[0].subtitle_DE : props.WelcomeText[0].subtitle_ENG}
          </p>
          <p className="pre-wrap text-secondary text-center lead">
            {context.lang.get === "en" ? props.WelcomeText[0].text_DE : props.WelcomeText[0].text_ENG}
          </p> */}
        </div>
      </section>

      {giftsFiltered.map((item) => (
        <Fragment>
          <div className="striped-box">
            <Container>
              <Row style={{ paddingBottom: "48px", paddingTop: "48px" }} xs={1} md={2}>
                <Col>
                  <div style={styleColumnLeft}>
                    <Image variant="top" src={item.image1} style={styleMainImage} fluid />
                  </div>
                </Col>
                <Col>
                  <h1 className="mb-3">{context.lang.get === "de" ? item.string_ENG : item.string_DE}</h1>
                  <div className="mb-3">
                    {context.lang.get === "en"
                      ? item.desc_DE.split("\n").map((item, i) => (
                          <div className="mb-3" key={i}>
                            {item}
                          </div>
                        ))
                      : item.desc_ENG.split("\n").map((item, i) => (
                          <div className="mb-3" key={i}>
                            {item}
                          </div>
                        ))}
                  </div>
                  <div className="font-weight-bold mb-3">CHF {item.price ? item.price : null}</div>
                  <Button href="#neworder">{context.lang.get === "de" ? "     ORDER     " : "   BESTELLEN   "}</Button>
                </Col>
              </Row>
              <Row style={{ paddingBottom: "48px" }} xs={1} md={3}>
                <Col>
                  {item.image2 ? (
                    <div style={styleColumnLeft}>
                      <Image src={item.image2} style={styleImage} fluid />
                    </div>
                  ) : null}
                </Col>
                <Col>
                  {item.image3 ? (
                    <div style={{ textAlign: "left" }}>
                      <Image src={item.image3} style={styleImage} fluid />
                    </div>
                  ) : null}
                </Col>
                <Col>
                  {item.image3 ? (
                    <div style={{ textAlign: "left" }}>
                      <Image src={item.image4} style={styleImage} fluid />
                    </div>
                  ) : null}
                </Col>
              </Row>
            </Container>
          </div>
        </Fragment>
      ))}
      <OrderForm />

      <Footer />
      <Copyrights />
    </Fragment>
  );
};

export default DisplayGeschenke;
