import React, { Component, Fragment } from "react";

class Copyrights extends Component {
	render() {
		return (
			<Fragment>
				<section className="copyright py-4 text-center text-white">
					<div className="container">
						<small className="pre-wrap">Copyright © SwissPresents.ch 2023</small>
					</div>
				</section>
				{/* Scroll to Top Button (Only visible on small and extra-small screen sizes)*/}
				<div className="scroll-to-top d-lg-none position-fixed">
					<a className="js-scroll-trigger d-block text-center text-white rounded" href="#page-top">
						<i className="fa fa-chevron-up"></i>
					</a>
				</div>
				{/* Bootstrap core JS*/}
				<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
				<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.bundle.min.js"></script>
				{/* Third party plugin JS*/}
				<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.4.1/jquery.easing.min.js"></script>
				{/* Contact form JS*/}
				<script src="assets/mail/jqBootstrapValidation.js"></script>
				<script src="assets/mail/contact_me.js"></script>
				{/* Core theme JS*/}
				<script src="js/scripts.js"></script>
			</Fragment>
		);
	}
}
export default Copyrights;
