import Offer1 from "../Assets/Img/Offers/offer1.jpg";
import Offer2 from "../Assets/Img/Offers/offer2.jpg";
import Offer3 from "../Assets/Img/Offers/Location.jpg";
import Offer4 from "../Assets/Img/Offers/offer4.jpg";

import Weihnachtsbaum_mit_Schokolade_Main from "../Assets/Img/Geschenke/Weihnachtsbaum_mit_Schokolade_Main.jpeg"
import Weihnachtsbaum_mit_Schokolade_1 from "../Assets/Img/Geschenke/Weihnachtsbaum_mit_Schokolade_1.jpeg"
import Weihnachtsbaum_mit_Schokolade_2 from "../Assets/Img/Geschenke/Weihnachtsbaum_mit_Schokolade_2.jpeg"
import Weihnachtsbaum_mit_Schokolade_3 from "../Assets/Img/Geschenke/Weihnachtsbaum_mit_Schokolade_3.jpeg"

import Weihnachtssterne_Rote_Main from "../Assets/Img/Geschenke/Weihnachtssterne_Rote_Main.jpeg"
import Weihnachtssterne_Rote_1 from "../Assets/Img/Geschenke/Weihnachtssterne_Rote_1.jpeg"
import Weihnachtssterne_Rote_2 from "../Assets/Img/Geschenke/Weihnachtssterne_Rote_2.jpeg"
import Weihnachtssterne_Rote_3 from "../Assets/Img/Geschenke/Weihnachtssterne_Rote_3.jpeg"

import Weihnachtssterne_Weise_Main from "../Assets/Img/Geschenke/Weihnachtssterne_Weise_Main.jpeg"
import Weihnachtssterne_Weise_1 from "../Assets/Img/Geschenke/Weihnachtssterne_Weise_1.jpeg"
import Weihnachtssterne_Weise_2 from "../Assets/Img/Geschenke/Weihnachtssterne_Weise_2.jpeg"
import Weihnachtssterne_Weise_3 from "../Assets/Img/Geschenke/Weihnachtssterne_Weise_3.jpeg"

import Main_Geburtstag from "../Assets/Img/Geschenke/Main_Geburtstag.jpg";
import Main_Hochzeit from "../Assets/Img/Geschenke/Main_Hochzeit.jpeg";
import Main_Muttertag from "../Assets/Img/Geschenke/Main_Muttertag.jpeg";
import Main_Oster from "../Assets/Img/Geschenke/Main_Oster.jpeg";
import Main_Verschiedene from "../Assets/Img/Geschenke/Main_Verschiedene.jpeg";
import Main_Weihnacht from "../Assets/Img/Geschenke/Main_Weihnacht.jpg";

import Oster_Krokus_Primavera_Blau_Main from "../Assets/Img/Geschenke/Oster_Krokus_Primavera_Blau_Main.jpeg";
import Oster_Krokus_Primavera_Blau_1 from "../Assets/Img/Geschenke/Oster_Krokus_Primavera_Blau_1.jpeg";
import Oster_Krokus_Primavera_Blau_2 from "../Assets/Img/Geschenke/Oster_Krokus_Primavera_Blau_2.jpeg";
import Oster_Krokus_Primavera_Blau_3 from "../Assets/Img/Geschenke/Oster_Krokus_Primavera_Blau_3.jpeg";

import Oster_Krokus_Primavera_Lila_Main from "../Assets/Img/Geschenke/Oster_Krokus_Primavera_Lila_Main.jpeg";
import Oster_Krokus_Primavera_Lila_1 from "../Assets/Img/Geschenke/Oster_Krokus_Primavera_Lila_1.jpeg";
import Oster_Krokus_Primavera_Lila_2 from "../Assets/Img/Geschenke/Oster_Krokus_Primavera_Lila_2.jpeg";
import Oster_Krokus_Primavera_Lila_3 from "../Assets/Img/Geschenke/Oster_Krokus_Primavera_Lila_3.jpeg";

import Oster_Krokus_Primavera_Rossa_Main from "../Assets/Img/Geschenke/Oster_Krokus_Primavera_Rossa_Main.jpeg";
import Oster_Krokus_Primavera_Rossa_1 from "../Assets/Img/Geschenke/Oster_Krokus_Primavera_Rossa_1.jpeg";
import Oster_Krokus_Primavera_Rossa_2 from "../Assets/Img/Geschenke/Oster_Krokus_Primavera_Rossa_2.jpeg";
import Oster_Krokus_Primavera_Rossa_3 from "../Assets/Img/Geschenke/Oster_Krokus_Primavera_Rossa_3.jpeg";

import Oster_Weisse_Narzisse_Main from "../Assets/Img/Geschenke/Oster_Weisse_Narzisse_Main.jpeg";
import Oster_Weisse_Narzisse_1 from "../Assets/Img/Geschenke/Oster_Weisse_Narzisse_1.jpeg";
import Oster_Weisse_Narzisse_2 from "../Assets/Img/Geschenke/Oster_Weisse_Narzisse_2.jpeg";
import Oster_Weisse_Narzisse_3 from "../Assets/Img/Geschenke/Oster_Weisse_Narzisse_3.jpeg";

import Raffaello_Baum_Weiss_Topf_Main from "../Assets/Img/Geschenke/Raffaello_Baum_Weiss_Topf_Main.jpeg";
import Raffaello_Baum_Weiss_Topf_1 from "../Assets/Img/Geschenke/Raffaello_Baum_Weiss_Topf_1.jpeg";

import Raffaello_Baum_Braun_Topf_Main from "../Assets/Img/Geschenke/Raffaello_Baum_Braun_Topf_Main.jpeg";
import Raffaello_Baum_Braun_Topf_1 from "../Assets/Img/Geschenke/Raffaello_Baum_Braun_Topf_1.jpeg";

import Schoko_Baum_Weiss_Topf_Main from "../Assets/Img/Geschenke/Schoko_Baum_Weiss_Topf_Main.jpeg";
import Schoko_Baum_Weiss_Topf_1 from "../Assets/Img/Geschenke/Schoko_Baum_Weiss_Topf_1.jpeg";

import Herz_Rose_Rosen_Main from "../Assets/Img/Geschenke/Herz_Rose_Rosen_Main.jpeg";
import Herz_Rose_Rosen_1 from "../Assets/Img/Geschenke/Herz_Rose_Rosen_1.jpeg";
import Herz_Rose_Rosen_2 from "../Assets/Img/Geschenke/Herz_Rose_Rosen_2.jpeg";
import Herz_Rose_Rosen_3 from "../Assets/Img/Geschenke/Herz_Rose_Rosen_3.jpeg";

import Herz_Weisse_Rosen_Main from "../Assets/Img/Geschenke/Herz_Weisse_Rosen_Main.jpeg";
import Herz_Weisse_Rosen_1 from "../Assets/Img/Geschenke/Herz_Weisse_Rosen_1.jpeg";
import Herz_Weisse_Rosen_2 from "../Assets/Img/Geschenke/Herz_Weisse_Rosen_2.jpeg";
import Herz_Weisse_Rosen_3 from "../Assets/Img/Geschenke/Herz_Weisse_Rosen_3.jpeg";

import Herz_Rote_Rosen_Main from "../Assets/Img/Geschenke/Herz_Rote_Rosen_Main.jpeg";
import Herz_Rote_Rosen_1 from "../Assets/Img/Geschenke/Herz_Rote_Rosen_1.jpeg";
import Herz_Rote_Rosen_2 from "../Assets/Img/Geschenke/Herz_Rote_Rosen_2.jpeg";
import Herz_Rote_Rosen_3 from "../Assets/Img/Geschenke/Herz_Rote_Rosen_3.jpeg";

import Blumenstrauss_Rosen_Main from "../Assets/Img/Geschenke/Blumenstrauss_Rosen_Main.jpeg";
import Blumenstrauss_Rosen_1 from "../Assets/Img/Geschenke/Blumenstrauss_Rosen_1.jpeg";
import Blumenstrauss_Rosen_2 from "../Assets/Img/Geschenke/Blumenstrauss_Rosen_2.jpeg";
import Blumenstrauss_Rosen_3 from "../Assets/Img/Geschenke/Blumenstrauss_Rosen_3.jpeg";

import Raffaello_Herz_Main from "../Assets/Img/Geschenke/Raffaello_Herz_Main.jpeg";
import Raffaello_Herz_1 from "../Assets/Img/Geschenke/Raffaello_Herz_1.jpeg";
import Raffaello_Herz_2 from "../Assets/Img/Geschenke/Raffaello_Herz_2.jpeg";
import Raffaello_Herz_3 from "../Assets/Img/Geschenke/Raffaello_Herz_3.jpeg";

import Ferrero_Rocher_Marienkäfer_Main from "../Assets/Img/Geschenke/Ferrero_Rocher_Marienkäfer_Main.jpeg";
import Ferrero_Rocher_Marienkäfer_1 from "../Assets/Img/Geschenke/Ferrero_Rocher_Marienkäfer_1.jpeg";
import Ferrero_Rocher_Marienkäfer_2 from "../Assets/Img/Geschenke/Ferrero_Rocher_Marienkäfer_2.jpeg";
import Ferrero_Rocher_Marienkäfer_3 from "../Assets/Img/Geschenke/Ferrero_Rocher_Marienkäfer_3.jpeg";

import Veloblumen_Main from "../Assets/Img/Geschenke/Veloblumen_Main.jpeg";
import Veloblumen_1 from "../Assets/Img/Geschenke/Veloblumen_1.jpeg";
import Veloblumen_2 from "../Assets/Img/Geschenke/Veloblumen_2.jpeg";
import Veloblumen_3 from "../Assets/Img/Geschenke/Veloblumen_3.jpeg";

export const MenuOptions = [
	{
		string_DE: "AKTION",
		string_ENG: "OFFERS ",
		ancor: "#aktion",
	},
	{
		string_DE: "GEBURTSTAG",
		string_ENG: "BIRTHDAY",
		ancor: "geschenke/geburtstag",
	},
	{
		string_DE: "WEIHNACHT",
		string_ENG: "CHRISTMAS",
		ancor: "geschenke/weihnachtsgeschenke",
	},
	{
		string_DE: "OSTER",
		string_ENG: "EASTER",
		ancor: "geschenke/ostergeschenke",
	},
	{
		string_DE: "HOCHZEIT",
		string_ENG: "WEDDING",
		ancor: "geschenke/hochzeitgeschenke",
	},
	{
		string_DE: "MUTTERTAG",
		string_ENG: "MOTHER'S DAY",
		ancor: "geschenke/muttertag",
	},
	{
		string_DE: "LIEFERUNG",
		string_ENG: "DELIVERY",
		ancor: "#lieferung",
	},
	{
		string_DE: "NEUE BESTELLUNG",
		string_ENG: "   NEW ORDER   ",
		ancor: "#neworder",
	},
];

export const BannerText = [
	{
		title_DE: "SWISS PRESENTS",
		title_ENG: "SWISS PRESENTS",
		subtitle_DE: "Einzigartige Süsse Geschenke",
		subtitle_ENG: "",
		text_DE:
			"",
		text_ENG:
			"",
	},
];

export const WelcomeText = [
	{
		title_DE: "WILLKOMMEN",
		title_ENG: "WELCOME ",
		subtitle_DE: "SWISS SÜSSE PRESENTS",
		subtitle_ENG: "",
		text_DE:
			"Überraschen Sie Ihre Liebsten mit unseren handdekorierten Geschenkartikeln. Dieses aussergewöhnliche Arrangement haben wir mit Hingabe ausgeschmückt und verwendeten dafür Schokotrüffel: Lindt, Raffaello, Ferrero Rosher, Toblerone, Milky Way usw. Mit unseren süßen Präsenten vermitteln Sie zu jedem Anlass die besondere Aufmerksamkeit.",
		text_ENG:
			"",
	},
];


export const WelcomeOrderText = [
	{
		title_DE: "NEUE BESTELLUNG",
		title_ENG: "NEW ORDER",
	},
];

export const WelcomeContactText = [
	{
		title_DE: "KONTAKT",
		title_ENG: "CONTACT",
	},
];

export const GiftCategories = [
		{
		image: Main_Geburtstag,
		image_page_header: "/img/Geburtstag_Page_Header.jpeg",
		title_DE: "Geburtstagsgeschenke",
		title_ENG: "",
		text_DE: "Geschenke zum Geburtstag",
		text_ENG: "",
		id: "geburtstag"
	},
	{
		image: Main_Weihnacht,
		image_page_header: "/img/Weihnachts_Page_Header.jpeg",
		title_DE: "Weihnachtsgeschenke",
		title_ENG: "",
		text_DE: "Geschenke für Weihnachten",
		text_ENG: "",
		id: "weihnachtsgeschenke"
	},
	{
		image: Main_Hochzeit,
		image_page_header: "/img/Hochzeit_Page_Header.jpeg",
		title_DE: "Hochzeitsgeschenke",
		title_ENG: "",
		text_DE: "Geschenke zur Hochzeit",
		text_ENG: "",
		id: "hochzeitgeschenke"
	},
	{
		image: Main_Muttertag,
		image_page_header: "/img/Muttertag_Page_Header.jpeg",
		title_DE: "Muttertagsgeschenke",
		title_ENG: "",
		text_DE: "Geschenke für den Muttertag",
		text_ENG: "",
		id: "muttertag"
	},
	{
		image: Main_Oster,
		image_page_header: "/img/Ostern_Page_Header.jpeg",
		title_DE: "Ostergeschenke",
		title_ENG: "",
		text_DE: "Geschenke zu Ostern",
		text_ENG: "",
		id: "ostergeschenke"
	},
	{
		image: Main_Verschiedene,
		image_page_header: "/img/Verschiedene_Page_Header.jpeg",
		title_DE: "Verschiedene Geschenke",
		title_ENG: "",
		text_DE: "Geschenke für jeden Anlass",
		text_ENG: "",
		id: "andere"
	},
];

export const LieferungText = [
	{
		title_DE: "Ort und Lieferung",
		text1_DE: "Unsere Firma befindet sich in Luzerne.",
		text2_DE: "",
		text3_DE: "",
		title_ENG: "",
		text1_ENG: "",
		text2_ENG: "",
		text3_ENG: "",
	},
	{
		title_DE: "Lieferung",
		text1_DE: "Wir liefern Ihre Bestellung in der Stadt Luzern, Emmen oder Meggen gratis aus.",
		text2_DE: "Eine Lieferung ist auch möglich nach Cham, Zug, Steinhausen, Wädenswil, Richterswil, Horgen, Thalwil, Adliswil, Langnau am Albis, Kilchberg für CHF 20. Bitte kontaktieren Sie uns, um einen Liefertermin zu vereinbaren.",
		text3_DE: "",
		title_ENG: "",
		text1_ENG: "",
		text2_ENG: "",
		text3_ENG: "",
	},
	{
		title_DE: "Abholung",
		text1_DE: "Sie können Ihre Bestellung in der Nähe von Luzern LIDO selbst abholen.",
		text2_DE: "",
		text3_DE: "",
		title_ENG: "",
		text1_ENG: "",
		text2_ENG: "",
		text3_ENG: "",
	},
	{
		title_DE: "Postversand Schweiz",
		text1_DE: "Die meisten unserer Produkte können per Post an jede Adresse in der Schweiz geliefert werden. Wir informieren Sie, sobald das Paket per A-Mail aufgegeben wurde, die Versandkosten betragen CHF 10.",
		text2_DE: "",
		text3_DE: "",
		title_ENG: "",
		text1_ENG: "",
		text2_ENG: "",
		text3_ENG: "",
	},
	{
		title_DE: "Postversand ins Ausland",
		text1_DE: "Leider können wir unsere Produkte nicht per Post ausserhalb der Schweiz liefern.",
		text2_DE: "",
		text3_DE: "",
		title_ENG: "",
		text1_ENG: "",
		text2_ENG: "",
		text3_ENG: "",
	},
	{
		title_DE: "Zahlung",
		text1_DE: "Sie können bar oder mit TWINT bezahlen. Sobald Sie Ihre Bestellung aufgegeben haben, bestätigen wir die Lieferzeit und organisieren die Zahlung.",
		text2_DE: "",
		text3_DE: "",
		title_ENG: "",
		text1_ENG: "",
		text2_ENG: "",
		text3_ENG: "",
	},
];
export const Gifts = [
	{
		category: "weihnachtsgeschenke",
		string_DE: "Weihnachtsbaum mit Schokolade",
		string_ENG: "",
		desc_DE:
			"Lindt Kugel Lindor: AssortiLindt Teddy, Lindt Frohes Fest \nGrösse: ca.15cmx15cm Höhe ca.32cm \nWir bieten diese Geschenke mit Moscato, Prosecco oder ohne Alkohol JaMaDu an.  \nGeschenk zur Weihnacht. Der Weihnachtsbaum Moscato, Prosecco oder ohne Alkohol JaMaDu, mit Schokolade Lindt ist ein schönes Geschenk für jede Familie und Freunde. Dieses Geschenk ist auch eine schöne Idee für Saaldekorationen und andere Räume für Ihre Gäste.",
		desc_ENG:
			"",
		price: 50,
		image1: Weihnachtsbaum_mit_Schokolade_Main,
		image2: Weihnachtsbaum_mit_Schokolade_1,
		image3: Weihnachtsbaum_mit_Schokolade_2,
		image4: Weihnachtsbaum_mit_Schokolade_3,
	},
	{
		category: "weihnachtsgeschenke",
		string_DE: "Weihnachtssterne Rote",
		string_ENG: "",
		desc_DE:
			"KUGEL: 7 St. Schweizer Milchschokolade Lindt \nGRÖSSE: ca. 22x22cm Höhe ca. 18 cm \nGeschenk zur Weihnacht. Der Weihnachtsstern, auch Adventsstern, mit Schokolade Lind ist ein süsses Geschenk für jede Familie und Freunden. Dieses Geschenk ist auch eine schöne Idee für Saaldekorationen und andere Räume für Ihre Gäste. Sie werden in jeder Blume eine versteckte «Lindt Schweizer Schokolade Trüffelkugel» finden",
		desc_ENG:
			"",
		price: 50,
		image1: Weihnachtssterne_Rote_Main,
		image2: Weihnachtssterne_Rote_1,
		image3: Weihnachtssterne_Rote_2,
		image4: Weihnachtssterne_Rote_3,
	},
	{
		category: "weihnachtsgeschenke",
		string_DE: "Weihnachtssterne Weisse",
		string_ENG: "",
		desc_DE:
			"KUGEL: 7 St. Schweizer Milchschokolade Lindt \nGRÖSSE: ca. 22x22cm Höhe ca. 18 cmv \nGeschenk zur Weihnacht. Der Weihnachtsstern, auch Adventsstern, mit Schokolade Lind ist ein süsses Geschenk für jede Familie und Freunden. Dieses Geschenk ist auch eine schöne Idee für Saaldekorationen und andere Räume für Ihre Gäste. Sie werden in jeder Blume eine versteckte «Lindt Schweizer Schokolade Trüffelkugel» finden",
		desc_ENG:
			"",
		price: 50,
		image1: Weihnachtssterne_Weise_Main,
		image2: Weihnachtssterne_Weise_1,
		image3: Weihnachtssterne_Weise_2,
		image4: Weihnachtssterne_Weise_3,
	},
	{
		category: "ostergeschenke",
		string_DE: "Weisse Narzisse",
		string_ENG: "",
		desc_DE:
			"KUGEL: 11 St. Schweizer Haselnuss Schokolade Lindt \nGRÖSSE: ca. 14x15cm Höhe ca.15 cm (bis Band 24cm) \n\nDas ist ein süßes Geschenk für Ihre Freundin, Kollegin, Schwester, Mutter, Frau, Tochter und eine Familie. Es ist auch eine wunderbare Idee für Tischdekoration.",
		desc_ENG:
			"",
		price: 50,
		image1: Oster_Weisse_Narzisse_Main,
		image2: Oster_Weisse_Narzisse_1,
		image3: Oster_Weisse_Narzisse_2,
		image4: Oster_Weisse_Narzisse_3,
	},
	{
		category: "ostergeschenke",
		string_DE: "Krokus Primavera Lila",
		string_ENG: "",
		desc_DE:
			"KUGEL: 11 St. Schweizer Haselnuss Schokolade Lindt \nGRÖSSE: ca. 21cm Höhe ca.15 cm (bis Band 24cm) \n\nDas ist ein süßes Geschenk für Ihre Freundin, Kollegin, Schwester, Mutter, Frau, Tochter und eine Familie. Es ist auch eine wunderbare Idee für Tischdekoration.",
		desc_ENG:
			"",
		price: 50,
		image1: Oster_Krokus_Primavera_Lila_Main,
		image2: Oster_Krokus_Primavera_Lila_1,
		image3: Oster_Krokus_Primavera_Lila_2,
		image4: Oster_Krokus_Primavera_Lila_3,
	},
	{
		category: "ostergeschenke",
		string_DE: "Krokus Primavera Rossa",
		string_ENG: "",
		desc_DE:
			"KUGEL: 11 St. Schweizer Haselnuss Schokolade Lindt \nGRÖSSE: ca. 14x15cm Höhe ca.15 cm (bis Band 24cm) \n\nDas ist ein süßes Geschenk für Ihre Freundin, Kollegin, Schwester, Mutter, Frau, Tochter und eine Familie. Es ist auch eine wunderbare Idee für Tischdekoration.",
		desc_ENG:
			"",
		price: 50,
		image1: Oster_Krokus_Primavera_Rossa_Main,
		image2: Oster_Krokus_Primavera_Rossa_1,
		image3: Oster_Krokus_Primavera_Rossa_2,
		image4: Oster_Krokus_Primavera_Rossa_3,
	},
	{
		category: "ostergeschenke",
		string_DE: "Krokus Primavera Blau",
		string_ENG: "",
		desc_DE:
			"KUGEL: 11 St. Schweizer Haselnuss Schokolade Lindt \nGRÖSSE: ca. 14x15cm Höhe ca.15 cm (bis Band 24cm) \n\nDas ist ein süßes Geschenk für Ihre Freundin, Kollegin, Schwester, Mutter, Frau, Tochter und eine Familie. Es ist auch eine wunderbare Idee für Tischdekoration.",
		desc_ENG:
			"",
		price: 50,
		image1: Oster_Krokus_Primavera_Blau_Main,
		image2: Oster_Krokus_Primavera_Blau_1,
		image3: Oster_Krokus_Primavera_Blau_2,
		image4: Oster_Krokus_Primavera_Blau_3,
	},
	{
		category: "hochzeitgeschenke",
		string_DE: "Raffaello Baum Weiss Topf",
		string_ENG: "",
		desc_DE:
			"KUGEL: Raffaello \nGRÖSSE: ca. 11cm Höhe ca. 32 cm (bis Band 34 cm)  \n\nDas ist ein Geschenk für eine Person sowie eine süsse Idee für Tischdekoration für Ihre Gäste.",
		desc_ENG:
			"",
		price: 50,
		image1: Raffaello_Baum_Weiss_Topf_Main,
		image2: Raffaello_Baum_Weiss_Topf_1,
		image3: null,
		image4: null,
	},
	{
		category: "hochzeitgeschenke",
		string_DE: "Herz Rose Rosen",
		string_ENG: "",
		desc_DE:
			"KUGEL: Schweizer Milchschokolade Lindt \nGRÖSSE: ca. 16x21cm Höhe ca. 10 cm (bis Band 16 cm) \n\nDieses Geschenk ist für jemanden besonderen. Das ist ein süßes Geschenk für Ihre Freundin, Schwester, Mutter, Frau, Tochter usw. Grosses Herz mit Blumen, die jeweils mit einer versteckten Lindt Schweizer Milchschokolade Trüffelkugel dekoriert.",
		desc_ENG:
			"",
		price: 75,
		image1: Herz_Rose_Rosen_Main,
		image2: Herz_Rose_Rosen_1,
		image3: Herz_Rose_Rosen_2,
		image4: Herz_Rose_Rosen_3,
	},
	{
		category: "hochzeitgeschenke",
		string_DE: "Herz Weisse Rosen",
		string_ENG: "",
		desc_DE:
			"KUGEL: Schweizer Milchschokolade Lindt \nGRÖSSE: ca. 16x21cm Höhe ca. 10 cm (bis Band 16 cm) \n\nDieses Geschenk ist für jemanden besonderen. Das ist ein süßes Geschenk für Ihre Freundin, Schwester, Mutter, Frau, Tochter usw. Grosses Herz mit Blumen, die jeweils mit einer versteckten Lindt Schweizer Milchschokolade Trüffelkugel dekoriert.",
		desc_ENG:
			"",
		price: 75,
		image1: Herz_Weisse_Rosen_Main,
		image2: Herz_Weisse_Rosen_1,
		image3: Herz_Weisse_Rosen_2,
		image4: Herz_Weisse_Rosen_3,
	},
	{
		category: "hochzeitgeschenke",
		string_DE: "Blumenstrauss Rosen",
		string_ENG: "",
		desc_DE:
			"KUGEL: Schweizer Schokolade Lindt \nGRÖSSE: ca. Diameter 23cm Höhe ca.26cm (plus Folie transparent) \n\nGeschenk zur Hochzeit und Geburtstag. Dieser Blumenstrauss ist für jemanden besonderen. Überraschen Sie Ihre Liebsten mit unseren handdekorierten Geschenkartikeln. Sie werden eine versteckte «Lindt Schweizer Schokolade Trüffelkugel» in jeder Blume finden. \n\n*** Versand per Post unmöglich",
		desc_ENG:
			"",
		price: 100,
		image1: Blumenstrauss_Rosen_Main,
		image2: Blumenstrauss_Rosen_1,
		image3: Blumenstrauss_Rosen_2,
		image4: Blumenstrauss_Rosen_3,
	},
	{
		category: "hochzeitgeschenke",
		string_DE: "Raffaello Herz",
		string_ENG: "",
		desc_DE:
			"RAFFAELLO: 11 Stück \nGRÖSSE: ca.18 x 16 cm Höhe ca.6 cm (bis Band 16 cm) \n\nEs ist ein süsses Geschenk für Ihre Frau, Tochter, Mutter, Freundin, Kollegin und eine Familie zu jedem Anlass: Geburtstag, Muttertag, Hochzeit usw. Das Raffaello-Herz ist auch eine schöne Idee für Saal und Tischdekorationen. Perfekt zur Hochzeit oder Geburtstag. Ihre Gäste können kleine Süssigkeiten überall geniessen.",
		desc_ENG:
			"",
		price: 60,
		image1: Raffaello_Herz_Main,
		image2: Raffaello_Herz_1,
		image3: Raffaello_Herz_2,
		image4: Raffaello_Herz_3,
	},
	{
		category: "geburtstag",
		string_DE: "Raffaello Baum Weiss Topf",
		string_ENG: "",
		desc_DE:
			"KUGEL: Raffaello \nGRÖSSE: ca. 11cm Höhe ca. 32 cm (bis Band 34 cm)  \n\nDas ist ein Geschenk für eine Person sowie eine süsse Idee für Tischdekoration für Ihre Gäste.",
		desc_ENG:
			"",
		price: 50,
		image1: Raffaello_Baum_Weiss_Topf_Main,
		image2: Raffaello_Baum_Weiss_Topf_1,
		image3: null,
		image4: null,
	},
	{
		category: "geburtstag",
		string_DE: "Schoko Baum Weiss Topf",
		string_ENG: "",
		desc_DE:
			"SCHOKO: Twix minis, Twix miniatures, Snickers minis, Snickers miniatures, Bounty minis, Milky Way minis, Schoko BonBons. \nGRÖSSE: ca. 12cm Höhe ca. 29 cm (bis Band 32 cm) \n\nViele Kinder zusammen? Geburtstag von einem Kind? Das ist eine süsse Idee für Tischdekoration für Ihre jungen Gäste.",
		desc_ENG:
			"",
		price: 50,
		image1: Schoko_Baum_Weiss_Topf_Main,
		image2: Schoko_Baum_Weiss_Topf_1,
		image3: null,
		image4: null,
	},
	{
		category: "geburtstag",
		string_DE: "Herz Rose Rosen",
		string_ENG: "",
		desc_DE:
			"KUGEL: Schweizer Milchschokolade Lindt \nGRÖSSE: ca. 16x21cm Höhe ca. 10 cm (bis Band 16 cm) \n\nDieses Geschenk ist für jemanden besonderen. Das ist ein süßes Geschenk für Ihre Freundin, Schwester, Mutter, Frau, Tochter usw. Grosses Herz mit Blumen, die jeweils mit einer versteckten Lindt Schweizer Milchschokolade Trüffelkugel dekoriert.",
		desc_ENG:
			"",
		price: 75,
		image1: Herz_Rose_Rosen_Main,
		image2: Herz_Rose_Rosen_1,
		image3: Herz_Rose_Rosen_2,
		image4: Herz_Rose_Rosen_3,
	},
	{
		category: "geburtstag",
		string_DE: "Herz Weisse Rosen",
		string_ENG: "",
		desc_DE:
			"KUGEL: Schweizer Milchschokolade Lindt \nGRÖSSE: ca. 16x21cm Höhe ca. 10 cm (bis Band 16 cm) \n\nDieses Geschenk ist für jemanden besonderen. Das ist ein süßes Geschenk für Ihre Freundin, Schwester, Mutter, Frau, Tochter usw. Grosses Herz mit Blumen, die jeweils mit einer versteckten Lindt Schweizer Milchschokolade Trüffelkugel dekoriert.",
		desc_ENG:
			"",
		price: 75,
		image1: Herz_Weisse_Rosen_Main,
		image2: Herz_Weisse_Rosen_1,
		image3: Herz_Weisse_Rosen_2,
		image4: Herz_Weisse_Rosen_3,
	},
	{
		category: "geburtstag",
		string_DE: "Herz Rote Rosen",
		string_ENG: "",
		desc_DE:
			"KUGEL: Schweizer Milchschokolade Lindt \nGRÖSSE: ca. 16x21cm Höhe ca. 10 cm (bis Band 16 cm) \n\nDieses Geschenk ist für jemanden besonderen. Das ist ein süßes Geschenk für Ihre Freundin, Schwester, Mutter, Frau, Tochter usw. Grosses Herz mit Blumen, die jeweils mit einer versteckten Lindt Schweizer Milchschokolade Trüffelkugel dekoriert.",
		desc_ENG:
			"",
		price: 75,
		image1: Herz_Rote_Rosen_Main,
		image2: Herz_Rote_Rosen_1,
		image3: Herz_Rote_Rosen_2,
		image4: Herz_Rote_Rosen_3,
	},
	{
		category: "muttertag",
		string_DE: "Herz Rote Rosen",
		string_ENG: "",
		desc_DE:
			"KUGEL: Schweizer Milchschokolade Lindt \nGRÖSSE: ca. 16x21cm Höhe ca. 10 cm (bis Band 16 cm) \n\nDieses Geschenk ist für jemanden besonderen. Das ist ein süßes Geschenk für Ihre Freundin, Schwester, Mutter, Frau, Tochter usw. Grosses Herz mit Blumen, die jeweils mit einer versteckten Lindt Schweizer Milchschokolade Trüffelkugel dekoriert.",
		desc_ENG:
			"",
		price: 75,
		image1: Herz_Rote_Rosen_Main,
		image2: Herz_Rote_Rosen_1,
		image3: Herz_Rote_Rosen_2,
		image4: Herz_Rote_Rosen_3,
	},
	{
		category: "muttertag",
		string_DE: "Herz Rose Rosen",
		string_ENG: "",
		desc_DE:
			"KUGEL: Schweizer Milchschokolade Lindt \nGRÖSSE: ca. 16x21cm Höhe ca. 10 cm (bis Band 16 cm) \n\nDieses Geschenk ist für jemanden besonderen. Das ist ein süßes Geschenk für Ihre Freundin, Schwester, Mutter, Frau, Tochter usw. Grosses Herz mit Blumen, die jeweils mit einer versteckten Lindt Schweizer Milchschokolade Trüffelkugel dekoriert.",
		desc_ENG:
			"",
		price: 75,
		image1: Herz_Rose_Rosen_Main,
		image2: Herz_Rose_Rosen_1,
		image3: Herz_Rose_Rosen_2,
		image4: Herz_Rose_Rosen_3,
	},
	{
		category: "muttertag",
		string_DE: "Herz Weisse Rosen",
		string_ENG: "",
		desc_DE:
			"KUGEL: Schweizer Milchschokolade Lindt \nGRÖSSE: ca. 16x21cm Höhe ca. 10 cm (bis Band 16 cm) \n\nDieses Geschenk ist für jemanden besonderen. Das ist ein süßes Geschenk für Ihre Freundin, Schwester, Mutter, Frau, Tochter usw. Grosses Herz mit Blumen, die jeweils mit einer versteckten Lindt Schweizer Milchschokolade Trüffelkugel dekoriert.",
		desc_ENG: "",
		price: 75,
		image1: Herz_Weisse_Rosen_Main,
		image2: Herz_Weisse_Rosen_1,
		image3: Herz_Weisse_Rosen_2,
		image4: Herz_Weisse_Rosen_3,
	},
	{
		category: "andere",
		string_DE: "Ferrero Rocher Marienkäfer",
		string_ENG: "",
		desc_DE:
			"KUGEL: 7 St. Ferrero Rocher \nGRÖSSE: ca. 14x14cm Höhe ca. 8 cm (bis Band 12 cm) \nHat Ihre Kollegin Geburtstag oder treffen Sie sich mit Ihrer Freundin? Das ist ein süßes Geschenk für Ihre Freundin, Kollegin und Kinder.",
		desc_ENG: "",
		price: 40,
		image1: Ferrero_Rocher_Marienkäfer_Main,
		image2: Ferrero_Rocher_Marienkäfer_1,
		image3: Ferrero_Rocher_Marienkäfer_2,
		image4: Ferrero_Rocher_Marienkäfer_3,
	},
	{
		category: "andere",
		string_DE: "Veloblumen",
		string_ENG: "",
		desc_DE: "LINDT: 11 Stück \nGRÖSSE: ca.18 x 26 cm Höhe ca.18 cm (plus Folie transparent) \n\nDas ist ein süsses Geschenk für eine Frau, Tochter, Mutter, Freundin, Kollegin und eine Familie zu jedem Anlass: Geburtstag, Muttertag, Hochzeit usw. Das ist auch eine schöne Idee für Saal und Tischdekorationen.",
		desc_ENG: "",
		price: 50,
		image1: Veloblumen_Main,
		image2: Veloblumen_1,
		image3: Veloblumen_2,
		image4: Veloblumen_3,
	},
	{
		category: "andere",
		string_DE: "Raffaello Baum Braun Topf",
		string_ENG: "",
		desc_DE:
			"KUGEL: Raffaello Praline \nGRÖSSE: ca. 11cm Höhe ca. 24 cm (bis Band 27 cm) \n\nDas ist ein ein süßes Geschenk sowie eine süsse Idee für Tischdekoration für Ihre Gäste",
		desc_ENG: "",
		price: 40,
		image1: Raffaello_Baum_Braun_Topf_Main,
		image2: Raffaello_Baum_Braun_Topf_1,
		image3: null,
		image4: null,
	},
];


export default MenuOptions;
