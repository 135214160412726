import React, { Fragment, useContext } from "react";
import Icofont from "react-icofont";

import "../Assets/Css/body.css";
import "../Assets/Css/heading.css";
import "../Assets/Css/styles.css";
import AppContext from "./AppContext";
import { WelcomeOrderText } from "../Components/Texts.js";
import curvy_line from "../Assets/Img/curvy_line.png";

export const OrderForm = (props) => {
  const context = useContext(AppContext);
  return (
    <Fragment>
      <section className="page-section portfolio mb-0" id="neworder">
        <div className="container text-center">
          <div className="text-center">
            <h2 className="page-section-heading text-secondary mb-3 d-inline-block">
              {context.lang.get === "en" ? WelcomeOrderText[0].title_DE : WelcomeOrderText[0].title_ENG}
            </h2>
          </div>

          <div class="divider-custom  mb-0">
            <div class="divider-custom-line"></div>
            <div class="divider-custom-icon">
              <i class="fas fa-star"></i>
            </div>
            <div class="divider-custom-line"></div>
          </div>
          {/* <img src={curvy_line} className="mb-3" /> */}
          {/* <div className="divider-custom">
						<div className="divider-custom-line"></div>
						<div className="divider-custom-icon">
							<Icofont icon="icofont-tennis" />
							//<i className="fas fa-star"></i>
						</div>
						<div className="divider-custom-line"></div>
					</div> */}
        </div>

        <div className="container text-center" style={{ border: "0px solid #ffffff" }}>
          <div
            style={{
              position: "relative",
              height: "850px",
              overflow: "hidden",
              maxWidth: "100%",
              paddingBottom: "6px",
            }}
          >
            <iframe
              title="form"
              src="https://form.jotformeu.com/60226052559352"
              style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", border: "none" }}
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default OrderForm;
