import React, { Component, Fragment, useContext } from "react";
import { WelcomeContactText } from "../Components/Texts";
import AppContext from "../Components/AppContext";
import Icofont from "react-icofont";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export const Footer = (props) => {
  const context = useContext(AppContext);

  return (
    <footer class="">
      <section className="page-section bg-primary text-white mb-0" id="contact">
        <div className="container">
          <div className="text-center">
            <h2 className="page-section-heading text-white d-inline-block mb-0">
              {context.lang.get === "en" ? WelcomeContactText[0].title_DE : WelcomeContactText[0].title_ENG}
            </h2>
          </div>

          <div className="divider-custom divider-light mb-5">
            <div className="divider-custom-line"></div>
            <div className="divider-custom-icon">
              <Icofont icon="icofont-ui-call" />
            </div>
            <div className="divider-custom-line"></div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-4 mb-5">
              <div className="d-flex flex-column align-items-center">
                <div className="icon-contact mb-3">
                  <i className="fas fa-mobile-alt"></i>
                </div>
                <div className="text-white">Phone</div>
                <div className="lead font-weight-bold">(044) 586 71 77</div>
              </div>
            </div>
            <div className="col-lg-4 mb-5">
              <div className="d-flex flex-column align-items-center">
                <div className="icon-contact mb-3">
                  <Icofont icon="icofont-home" />
                </div>
                <div className="text-white">Addresse</div>
                <div className="lead font-weight-bold text-center">
                  CH-6006 <br></br>Luzern
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-5">
              <div className="d-flex flex-column align-items-center">
			  <Link to={{ pathname: "https://www.instagram.com/swisspresents/" }} target="_blank">
                <div className="icon-contact mb-3">
                 
                    <FontAwesomeIcon icon="fa-brands fa-instagram" />
                  
                </div>
				</Link>
				<div className="text-white">Instagram</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
};
export default Footer;
