import React, { Fragment, useContext } from "react";

import "../Assets/Css/body.css";
import "../Assets/Css/heading.css";
import "../Assets/Css/styles.css";
import AppContext from "./AppContext";
import { WelcomeText } from "./Texts.js";
import curvy_line from "../Assets/Img/curvy_line.png";
import Icofont from "react-icofont";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Welcome = () => {
  const context = useContext(AppContext);
  return (
    <Fragment>
      <section className="page-section" id="welcome_div" style={{ backgroundColor: "#fbf8f3", marginTop: "0px" }}>
        <div className="container text-center" style={{ paddingBlock: "12px" }}>
          <div className="text-center">
            <h2 className="page-section-heading text-secondary mb-3 d-inline-block">
              {context.lang.get === "en" ? WelcomeText[0].title_DE : WelcomeText[0].title_ENG}
            </h2>
          </div>

          <div className="divider-custom mb-3">
            <div className="divider-custom-line"></div>
            <div className="divider-custom-icon">
            <FontAwesomeIcon icon="fa-solid fa-candy-cane" />
            </div>
            <div className="divider-custom-line" ></div>
          </div>

          {/* <img src={curvy_line} className="mb-3" /> */}

          <p className="lead">{context.lang.get === "en" ? WelcomeText[0].subtitle_DE : WelcomeText[0].subtitle_ENG}</p>
          <p className="pre-wrap lead" style={{ textAlign: "justify" }}>
            {context.lang.get === "en" ? WelcomeText[0].text_DE : WelcomeText[0].text_ENG}
          </p>
        </div>
      </section>
    </Fragment>
  );
};

export default Welcome;
