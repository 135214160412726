import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./App.css";
import Home from "./Pages/Home";
import AppContext from "./Components/AppContext";
import DisplayGeschenke from "./Components/DisplayGeschenke";
import Lieferung from "./Components/Ort_Und_Lieferung";

const App = () => {
	const [lang, setLang] = useState("en");

	const store = {
		lang: { get: lang, set: setLang },
		//color: { get: color, set: setColor },
	};

	return (
		<AppContext.Provider value={store}>
			<div className="wrapper">
				<Router>
					<Switch>
						<Route exact path="/" render={(props) => <Home {...props} language={"de"} />} />						
						<Route exact path="/geschenke/:categoryId"  render={(props) => <DisplayGeschenke {...props} language={"de"} />} />
						<Route exact path="/lieferung"  render={(props) => <Lieferung {...props} language={"de"} />} />
					</Switch>
				</Router>
			</div>
		</AppContext.Provider>
	);
};

export default App;
