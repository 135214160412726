import React, { useContext, Fragment } from "react";
import { Helmet } from "react-helmet";
import "../Assets/Css/body.css";
import "../Assets/Css/heading.css";
import "../Assets/Css/styles.css";

import Header from "../Components/Header";
//import Footer from "../Components/Footer";
import ShowGeschenke from "../Components/Offers";
//import Facts from "../Components/Facts";
//import StringingClub from "../Components/StringingClub";
import WeihnachtsGeschenke from "../Components/DisplayGeschenke";
import Icofont from "react-icofont";

import Navigation from "../Components/Navigation";
import Welcome from "../Components/Welcome";
import Copyrights from "../Components/Copyrights";
import AppContext from "../Components/AppContext";
import OrderForm from "../Components/OrderForm";
import Footer from "../Components/Footer";

import {
	ProStrings,
	ClubStrings,
	WelcomTextProStrings,
	WelcomTextClubStrings,
	WelcomeContactText,
} from "../Components/Texts";

export const Home = (props) => {
	const context = useContext(AppContext);
	props.language === "en" ? context.lang.set("de") : context.lang.set("en");
	return (
		<Fragment>
			<Helmet>
				<html lang={props.language && props.language === "en" ? "en" : "de"} />
			</Helmet>
			<Navigation />
			<Header image_page_header={"/img/MainImage01.jpg"}/>
			<Welcome/>
			<ShowGeschenke />
			{/* <Facts /> */}
			{/* <WeihnachtsGeschenke Strings={ProStrings} WelcomeText={WelcomTextProStrings} SectionId="weihnachtsgeschenke" /> */}
			<OrderForm />
			
			<Footer />
			<Copyrights />
		</Fragment>
	);
};

export default Home;
