import React, { Fragment, useContext } from "react";
import { useParams } from "react-router-dom";

import { Helmet } from "react-helmet";
//import CardDeck from "react-bootstrap/CardDeck";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Icofont from "react-icofont";

import Header from "../Components/Header";

import Navigation from "../Components/Navigation";
import Copyrights from "../Components/Copyrights";
import AppContext from "../Components/AppContext";
import OrderForm from "../Components/OrderForm";
import Footer from "./Footer";
import { LieferungText } from "../Components/Texts";

import "../Assets/Css/body.css";
import "../Assets/Css/heading.css";
import "../Assets/Css/styles.css";
import curvy_line from "../Assets/Img/curvy_line.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { ProStrings, WelcomTextProStrings } from "./Texts.js";

const styleMainImage = {
  width: "auto",
  height: "auto",
  display: "block",
  marginLeft: "0px",
  marginRight: "auto",
  marginBottom: "24px",
};

const styleImage = {
  width: "auto",
  height: "auto",
  marginBottom: "12px",
};

const styleColumnLeft = {
  display: "block",
  marginRight: "auto",
  marginLeft: "0px",
};

export const Lieferung = (props) => {
  const context = useContext(AppContext);

  return (
    <Fragment>
      <Helmet>
        <html lang={props.language && props.language === "en" ? "en" : "de"} />
        {console.log(context.lang.get)}
      </Helmet>
      <Navigation />
      <Header image_page_header={"/img/MainImage01.jpg"} />
      <section className="page-section portfolio">
        <div className="container text-center">
          <div className="text-center">
            <h2 className="page-section-heading text-secondary mb-3 d-inline-block">Lieferung</h2>
          </div>

          <div class="divider-custom  mb-0">
            <div class="divider-custom-line"></div>
            <div class="divider-custom-icon">
              <FontAwesomeIcon icon="fa-solid fa-truck" />
            </div>
            <div class="divider-custom-line"></div>
          </div>
        </div>
      </section>
      {LieferungText.map((item) => (
        <Container>
          <Row style={{ paddingBottom: "24px", paddingTop: "24px" }}>
            <Col>
              <h2 className="mb-3">
                {props.language && props.language === "de" ? item.title_DE : item.title_ENG}
              </h2>
              {item && item.text1_DE !== "" ? 
              <div className="mb-3">
                {props.language && props.language === "de"
                  ? item.text1_DE.split("\n").map((item, i) => (
                      <div className="mb-3" key={i}>
                        {item}
                      </div>
                    ))
                  : item.text1_ENG.split("\n").map((item, i) => (
                      <div className="mb-3" key={i}>
                        {item}
                      </div>
                    ))}
              </div> : null}

              {item && item.text2_DE !== "" ? 
              <div className="mb-3">
                {props.language && props.language === "de"
                  ? item.text2_DE.split("\n").map((item, i) => (
                      <div className="mb-3" key={i}>
                        {item}
                      </div>
                    ))
                  : item.text2_ENG.split("\n").map((item, i) => (
                      <div className="mb-3" key={i}>
                        {item}
                      </div>
                    ))}
              </div> : null}

              {item && item.text3_DE !== "" ? 
              <div className="mb-3">
                {props.language && props.language === "de"
                  ? item.text3_DE.split("\n").map((item, i) => (
                      <div className="mb-3" key={i}>
                        {item}
                      </div>
                    ))
                  : item.text3_ENG.split("\n").map((item, i) => (
                      <div className="mb-3" key={i}>
                        {item}
                      </div>
                    ))}
              </div> : null}
            </Col>
          </Row>
        </Container>
      ))}
      ;
      <Footer />
      <Copyrights />
    </Fragment>
  );
};

export default Lieferung;
