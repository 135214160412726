import React, { Fragment, useContext } from "react";

import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import "../Assets/Css/body.css";
import "../Assets/Css/heading.css";
import "../Assets/Css/styles.css";
import AppContext from "./AppContext";
import { GiftCategories } from "./Texts.js";

export const ShowGeschenke = () => {
  const context = useContext(AppContext);
  return (
    <Fragment>
      <section class="page-section portfolio" id="offer">
        <div class="container">
          <div class="text-center">
            <h2 class="page-section-heading text-secondary mb-0 d-inline-block">
              {context.lang.get === "de" ? "OFFERS" : "ANGEBOT"}
            </h2>
          </div>

          <div class="divider-custom  mb-0">
            <div class="divider-custom-line"></div>
            <div class="divider-custom-icon">
              <i class="fas fa-star"></i>
            </div>
            <div class="divider-custom-line"></div>
          </div>
        </div>
      </section>

      <Container class="row justify-content-center mb-5">
        <Row style={{ paddingBottom: "12px" }} xs={1} md={2}>
          {GiftCategories.map((item) => (
            <Col>
              <Link to={"geschenke/" + item.id} preventScrollReset={true}>
                <Card
                  className="text-center"
                  class="portfolio-item mx-auto"
                  style={{ border: "none", textAlign: "center", marginBottom: "24px" }}
                >
                  <Card.Img
                    variant="top"
                    src={item.image}
                    style={{
                      width: "300px",

                      height: "auto",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      borderRadius: "8px",
                    }}
                  />
                  <Card.Body>
                    <div className="text-center h3">{context.lang.get === "en" ? item.title_DE : item.title_ENG}</div>
                    <Card.Text>
                      <div>{context.lang.get === "en" ? item.subtitle_DE : item.subtitle_ENG}</div>
                      {context.lang.get === "en" ? item.text_DE : item.text_ENG}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
      <Container class="row justify-content-center mb-5">
        <Row style={{ marginBottom: "60px" }}></Row>
      </Container>
    </Fragment>
  );
};

export default ShowGeschenke;
